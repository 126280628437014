<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Servicios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Servicios</li>
                  <li class="breadcrumb-item active">Internos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="col-md-12">
            <div class="card card-info card-outline">
              <div class="row justify-content-md-center">
                <div class="col-md-12">
                  <div class="card-header">
                    <h3 class="card-title text-black text-bold">
                      <i class="fas fa-file-alt"></i>
                      Detalle Solicitud
                    </h3>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-sm-3">
                        <div
                          class="description-block"
                          v-if="servicioInterno.id"
                        >
                          <p class="description-header">
                            <i class="fas fa-box"></i
                            >{{ " " + servicioInterno.id }}
                          </p>
                          <span class="description-text text-secondary"
                            >Servicio:</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div
                          class="description-block"
                          v-if="servicioInterno.tipo_servicio"
                        >
                          <p class="description-header">
                            <i class="fas fa-shipping-fast"></i
                            >{{ " " + servicioInterno.nTipoServicio }}
                          </p>
                          <span class="description-text text-secondary"
                            >Tipo Servicio:</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="description-block" v-if="bloque">
                          <p class="description-header">
                            <i class="fas fa-cube"></i>{{ " " + bloque.nombre }}
                          </p>
                          <span class="description-text text-secondary"
                            >Bloque:</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="description-block" v-if="sitio">
                          <p class="description-header">
                            <i class="fas fa-map-marked-alt"></i
                            >{{ " " + sitio.nombre }}
                          </p>
                          <span class="description-text text-secondary"
                            >Sitio:</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-3">
                        <div
                          class="description-block"
                          v-if="servicioInterno.empresa"
                        >
                          <p class="description-header">
                            <i class="fas fa-university"></i
                            >{{ " " + servicioInterno.empresa.razon_social }}
                          </p>
                          <span class="description-text text-secondary"
                            >Empresa:</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div
                          class="description-block"
                          v-if="postulacion.det_solicitud_interna"
                        >
                          <p class="description-header">
                            <i class="fas fa-truck-loading"></i
                            >{{
                              servicioInterno.tipo_servicio == 1
                                ? " " +
                                  postulacion.det_solicitud_interna.nTipoVh
                                : " " +
                                  postulacion.det_solicitud_interna.nTipoEqui
                            }}
                          </p>
                          <span class="description-text text-secondary"
                            >{{
                              servicioInterno.tipo_servicio == 1
                                ? "Tipo Vehículo"
                                : "Izaje"
                            }}:</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div
                          class="description-block"
                          v-if="postulacion.det_solicitud_interna"
                        >
                          <p class="description-header">
                            <i class="fas fa-clock"></i>
                            {{ postulacion.det_solicitud_interna.nTurno }}
                          </p>
                          <span class="description-text text-secondary"
                            >Turno:</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-3">
                        <div
                          class="description-block"
                          v-if="postulacion.equipo"
                        >
                          <p class="description-header">
                            <i class="fas fa-truck-moving"></i
                            >{{ " " + postulacion.equipo.placa }}
                          </p>
                          <span class="description-text text-secondary"
                            >{{
                              servicioInterno.tipo_servicio == 1
                                ? "Vehiculo"
                                : "Equipo"
                            }}:</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3" v-if="postulacion.remolque != null">
                        <div class="description-block">
                          <p class="description-header">
                            <i class="fas fa-truck-loading"></i
                            >{{ " " + postulacion.remolque.placa }}
                          </p>
                          <span
                            class="description-text text-secondary"
                            v-if="postulacion.remolque != null"
                            >Trailer:</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div
                          class="description-block"
                          v-if="postulacion.persona"
                        >
                          <p class="description-header">
                            <i class="fas fa-id-badge"></i
                            >{{
                              " " +
                                postulacion.persona.nombres +
                                " " +
                                postulacion.persona.apellidos
                            }}
                          </p>
                          <span class="description-text text-secondary"
                            >{{
                              servicioInterno.tipo_servicio == 1
                                ? "Conductor"
                                : "Operario"
                            }}:</span
                          >
                        </div>
                      </div>
                      <div class="col-sm-3" v-if="postulacion.aparejador_id">
                        <div class="description-block">
                          <p class="description-header">
                            <i class="fas fa-user-tie"></i
                            >{{
                              " " +
                                postulacion.aparejador.nombres +
                                " " +
                                postulacion.aparejador.apellidos
                            }}
                          </p>
                          <span
                            class="description-text text-secondary"
                            v-if="postulacion.aparejador_id"
                            >Aparejador:</span
                          >
                        </div>
                      </div>
                      <div
                        class="col-sm-3"
                        v-if="postulacion.det_solicitud_interna"
                      >
                        <div class="description-block">
                          <p class="description-header">
                            <i class="fas fa-comment-dots"></i
                            >{{
                              " " +
                                postulacion.det_solicitud_interna.observaciones
                            }}
                          </p>
                          <span class="description-text text-secondary"
                            >Observación:</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card card-info card-outline">
              <div class="row justify-content-md-center">
                <div class="col-md-12">
                  <div class="card-header">
                    <h3 class="card-title text-black text-bold">
                      <i class="fas fa-receipt"></i>
                      Servicio
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label>Fecha Inicial </label>
                        <div class="row">
                          <div class="col-md-7">
                            <input
                              type="date"
                              id="fecha_ini"
                              class="form-control form-control-sm"
                              v-model="servicioInterno.fecha_ini1"
                              :class="
                                $v.servicioInterno.fecha_ini1.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              @input="validaFecha"
                            />
                          </div>
                          <div class="col-md-5">
                            <input
                              type="text"
                              id="hora_ini1"
                              class="form-control form-control-sm"
                              v-model="servicioInterno.hora_ini1"
                              :class="
                                $v.servicioInterno.hora_ini1.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              v-mask="{
                                mask: '99:99',
                                hourFormat: '23',
                              }"
                              @change="validaFecha"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-3">
                        <label>Fecha Final </label>
                        <div class="row">
                          <div class="col-md-7">
                            <input
                              type="date"
                              id="fecha_fin"
                              class="form-control form-control-sm"
                              v-model="servicioInterno.fecha_fin1"
                              :class="
                                $v.servicioInterno.fecha_fin1.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              :disabled="
                                $refs.CsServicioDetalle &&
                                  $refs.CsServicioDetalle.csDetalles.length > 0
                              "
                              @input="validaFecha"
                            />
                          </div>
                          <div class="col-md-5">
                            <input
                              type="text"
                              id="hora_fin"
                              class="form-control form-control-sm"
                              v-model="servicioInterno.hora_fin1"
                              :class="
                                $v.servicioInterno.hora_fin1.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              v-mask="{
                                mask: '99:99',
                                hourFormat: '23',
                              }"
                              :disabled="
                                $refs.CsServicioDetalle &&
                                  $refs.CsServicioDetalle.csDetalles.length > 0
                              "
                              @change="validaFecha"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <label>Estado </label>
                        <select
                          id="estado"
                          class="form-control form-control-sm"
                          v-model="servicioInterno.estado"
                          @change="getIndex()"
                          disabled
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                            disabled
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-3" v-if="conductor_2">
                        <label for="trabajo_realizado"
                          >Segunda Persona/ Conductor</label
                        >
                        <input
                          type="text"
                          v-model="persona_dos.numero_documento"
                          :disabled="postulacion.persona_2_id"
                          class="form-control form-control-sm"
                          :class="
                            $v.persona_dos.id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @change="
                            servicioInterno.tipo_servicio == 1
                              ? buscarConductores()
                              : ''
                          "
                        />

                        <small
                          >{{ persona_dos.nombres }}
                          {{ persona_dos.apellidos }}</small
                        >
                      </div>
                      <div class="form-group col-md-12">
                        <label for="trabajo_realizado"
                          >Trabajo realizado (Geralizado)</label
                        >
                        <textarea
                          v-model="servicioInterno.trabajo_realizado"
                          placeholder="Especifique en este campo hora a hora lo que usted realizo durante el periodo de trabajo"
                          cols="30"
                          rows="5"
                          class="form-control form-control-sm"
                          :class="
                            $v.servicioInterno.trabajo_realizado.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CsServicioDetalle ref="CsServicioDetalle" v-if="detalle === 1" />
          <CsServicioDetalleSegundoConductor
            ref="CsServicioDetalleSegundoConductor"
            v-if="detalle === 1 && conductor_2"
          />
          <CsServicioPresupuesto
            ref="CsServicioPresupuesto"
            v-if="
              presupuesto === 1 &&
                $store.getters.can('cs.serviciosInterno.presupuesto')
            "
          />
          <CsServicioDetalleFirmas
            ref="CsServicioDetalleFirmas"
            v-if="servicioInterno.firmas && servicioInterno.firmas.length > 0"
          />
          <div class="card-footer">
            <div class="row">
              <button class="btn bg-secondary col-md-1" @click="back()">
                <i class="fas fa-reply"></i><br />Volver
              </button>
              <button
                class="btn bg-primary col-md-1"
                v-if="
                  $refs.CsServicioDetalle !== null &&
                    $refs.CsServicioDetalle != undefined &&
                    conductor_2 == false
                "
                v-show="
                  servicioInterno.estado < 3 &&
                    !$v.servicioInterno.$invalid &&
                    $refs.CsServicioDetalle.fin_reportes
                "
                @click="verificacionPresuspuesto()"
              >
                <i class="fas fa-paper-plane"></i><br />Guardar
              </button>
              <button
                class="btn bg-primary col-md-1"
                v-if="
                  $refs.CsServicioDetalle !== null &&
                    $refs.CsServicioDetalle != undefined &&
                    $refs.CsServicioDetalleSegundoConductor !== null &&
                    $refs.CsServicioDetalleSegundoConductor != undefined &&
                    conductor_2 == true
                "
                v-show="
                  servicioInterno.estado < 3 &&
                    !$v.servicioInterno.$invalid &&
                    $refs.CsServicioDetalle.fin_reportes &&
                    $refs.CsServicioDetalleSegundoConductor.fin_reportes
                "
                @click="verificacionPresuspuesto()"
              >
                <i class="fas fa-paper-plane"></i><br />Guardar
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, helpers } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import moment from "moment";
import CsServicioDetalle from "./CsServicioDetalle";
import CsServicioPresupuesto from "./CsServicioPresupuesto";
import CsServicioDetalleFirmas from "./CsServicioDetalleFirmas";
import CsServicioDetalleSegundoConductor from "./CsServicioDetalleSegundoConductor";

const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "CsServicioForm",
  components: {
    Loading,
    CsServicioDetalle,
    CsServicioPresupuesto,
    CsServicioDetalleFirmas,
    CsServicioDetalleSegundoConductor,
  },
  data() {
    return {
      cargando: false,
      sitio: null,
      conductor_2: false,
      bloque: null,
      ruta: null,
      postulacion: [],
      persona_dos: {
        id: null,
        numero_documento: null,
        nombres: null,
        apellidos: null,
      },
      servicioInterno: {
        persona_2_id: null,
        persona_2_type: null,
        id: null,
        tipo_servicio: null,
        turno: null,
        placa: null,
        persona_type: null,
        bloque_id: null,
        sitio_id: null,
        ruta_id: null,
        fecha_ini: null,
        fecha_ini1: null,
        hora_ini1: null,
        fecha_fin: null,
        fecha_fin1: null,
        hora_fin1: null,
        trabajo_realizado: null,
        estado: null,
      },
      listasForms: {
        tipos_servicios: [],
        tipo_presupuesto: [],
        tipo_tiempos: [],
        estados: [],
        /*      rutas: [], */
      },
      detalle: 0,
      presupuesto: 0,
    };
  },

  validations() {
    if (this.conductor_2) {
      return {
        servicioInterno: {
          fecha_ini1: {
            required,
          },
          hora_ini1: {
            required,
            timeFormat,
          },

          fecha_fin1: {
            required,
          },

          hora_fin1: {
            required,
            timeFormat,
          },
          trabajo_realizado: {
            required,
          },
        },
        persona_dos: {
          id: {
            required,
          },
        },
      };
    } else {
      return {
        servicioInterno: {
          fecha_ini1: {
            required,
          },
          hora_ini1: {
            required,
            timeFormat,
          },
          fecha_fin1: {
            required,
          },
          hora_fin1: {
            required,
            timeFormat,
          },
          trabajo_realizado: {
            required,
          },
        },
      };
    }
  },

  methods: {
    async getIndex() {
      let me = this;
      me.cargando = true;
      this.servicioInterno = {
        id: null,
        tipo_servicio: null,
        turno: null,
        placa: null,
        persona_type: null,
        bloque_id: null,
        sitio_id: null,
        ruta_id: null,
        fecha_ini: null,
        fecha_ini1: null,
        hora_ini1: null,
        fecha_fin: null,
        fecha_fin1: null,
        hora_fin1: null,
        trabajo_realizado: null,
        estado: null,
        persona_2_id: null,
        persona_2_type: null,
      };

      me.$route.params.servicio.fecha_ini = moment(
        me.$route.params.servicio.fecha_ini
      ).format("YYYY-MM-DD HH:mm");
      const inicio = moment(me.$route.params.servicio.fecha_ini);
      let fecha_ini1 = moment(inicio).format("YYYY-MM-DD");
      let hora_ini1 = moment("06:00", "HH:mm").format("HH:mm");

      // Se filtra el contenido de la fecha hora de fin
      let fecha_fin1 = null;
      let hora_fin1 = null;
      if (me.$route.params.servicio.fecha_fin) {
        me.$route.params.servicio.fecha_fin = moment(
          me.$route.params.servicio.fecha_fin
        ).format("YYYY-MM-DD HH:mm");
        const fin = moment(me.$route.params.servicio.fecha_fin);
        fecha_fin1 = moment(fin).format("YYYY-MM-DD");
        hora_fin1 = moment(fin).format("HH:mm");
      }
      let solicitudes_presupuestos = null;
      let clase_solicitud =
        me.$route.params.postulacion.det_solicitud_interna.solicitud_interna
          .clase_solicitud;
      if (
        me.$route.params.postulacion.det_solicitud_interna.solicitud_interna
          .solicitudes_presupuestos.length > 0
      ) {
        solicitudes_presupuestos =
          me.$route.params.postulacion.det_solicitud_interna.solicitud_interna
            .solicitudes_presupuestos;
      }
      let gerente =
        me.$route.params.postulacion.det_solicitud_interna.solicitud_interna
          .gerente_area;
      me.servicioInterno = {
        ...me.$route.params.servicio,
        solicitudes_presupuestos,
        clase_solicitud,
        fecha_ini1,
        hora_ini1,
        fecha_fin1,
        hora_fin1,
        gerente,
      };

      me.postulacion = { ...me.$route.params.postulacion };
      me.bloque = { ...me.$route.params.servicio.bloque };
      me.ruta = { ...me.$route.params.servicio.ruta };
      me.sitio = { ...me.$route.params.servicio.sitio };
      me.servicioInterno.persona.nombre_completo =
        me.$route.params.servicio.persona.nombres +
        " " +
        me.$route.params.servicio.persona.apellidos;
      me.detalle = me.$route.params.detalle;
      me.presupuesto = me.$route.params.presupuesto;

      me.servicioInterno.fecha_fin1 = moment(inicio).format("YYYY-MM-DD");
      me.servicioInterno.hora_fin1 =
        me.postulacion.det_solicitud_interna.turno == 2
          ? moment("18:00", "HH:mm").format("HH:mm")
          : me.postulacion.det_solicitud_interna.turno == 1
          ? moment("02:00", "HH:mm").format("HH:mm")
          : null;

      me.persona_dos = me.servicioInterno.persona_2
        ? me.servicioInterno.persona_2
        : {};

      if (
        !me.persona_dos.id &&
        me.postulacion.det_solicitud_interna.turno == 3
      ) {
        me.conductor_2 = me.postulacion.persona_2_id ? true : false;
        me.persona_dos = me.postulacion.persona2 ? me.postulacion.persona2 : {};
        const ini = moment(me.$route.params.servicio.fecha_ini);
        me.servicioInterno.fecha_fin1 = moment(ini)
          .add(1, "day")
          .format("YYYY-MM-DD");
        me.servicioInterno.hora_fin1 = moment("06:00", "HH:mm").format("HH:mm");
      }

      me.cargando = false;
    },

    async getTipoServicio() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/143").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoPresupuesto() {
      this.listasForms.tipo_presupuesto = [];
      axios.get("/api/lista/151").then((response) => {
        this.listasForms.tipo_presupuesto = response.data;
      });
    },

    async save() {
      this.servicioInterno.fecha_ini = `${this.servicioInterno.fecha_ini1} ${this.servicioInterno.hora_ini1}`;
      this.servicioInterno.fecha_fin = `${this.servicioInterno.fecha_fin1} ${this.servicioInterno.hora_fin1}`;
      if (!this.$v.servicioInterno.$invalid) {
        this.cargando = true;
        axios({
          method: "PUT",
          url: "/api/cs/servicios",
          data: this.servicioInterno,
        })
          .then((response) => {})
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async buscarConductores() {
      this.cargando = true;
      let dato = await this.persona_dos.numero_documento;
      let cond = null;
      let fecha_ini = await this.servicioInterno.fecha_ini1;
      let fecha_fin = await this.servicioInterno.fecha_fin1;

      let res = await this.validarStatus(dato, "C", fecha_ini, fecha_fin);

      if (res.data.length > 0) {
        cond = res["data"][0];
        //Valida la documentación de la persona
        if (res.validacion.error) {
          this.cargando = false;
          await this.msgDocumentacion(res);
          if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) {
            this.persona_dos = {};
            this.servicioInterno.persona_2_id = null;
            this.servicioInterno.persona_2_type = null;
            return false;
          }
        }

        //Valida la información de la persona
        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creación de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.persona_dos = {};
          this.servicioInterno.persona_2_id = null;
          this.servicioInterno.persona_2_type = null;
          this.cargando = false;
          return false;
        }

        //Valida la línea de negocio de la persona
        if (!res["has_lineas"]) {
          this.$swal({
            icon: "error",
            title: `El conductor no se encuentra en la línea de negocio Carga Seca`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.persona_dos = {};
          this.servicioInterno.persona_2_id = null;
          this.servicioInterno.persona_2_type = null;
          this.cargando = false;
          return false;
        }

        //Valida las postulaciones de la persona
        if (res.postulacion) {
          await this.$swal({
            icon: "error",
            title: `El conductor tiene postulaciones activas en otra solicitud.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.persona_dos = {};
          this.servicioInterno.persona_2_id = null;
          this.servicioInterno.persona_2_type = null;
          this.cargando = false;
          return false;
        }
      } else {
        //this.listasForms.persona[indexDet] = null;
        await this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.cargando = false;
        this.persona_dos = {};
        this.servicioInterno.persona_2_id = null;
        this.servicioInterno.persona_2_type = null;
        return false;
      }

      /*  if (this.servicioInterno.persona.id == cond["id"]) {
        this.$swal({
          icon: "error",
          title: `El segundo conductor No puede ser el mismo que el primer conductor`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.persona_dos = {};
        this.servicioInterno.persona_2_id = null;
        this.servicioInterno.persona_2_type = null;
        this.cargando = false;
        return false;
      } */

      await this.$swal({
        icon: "success",
        title:
          "El conductor cumple con los requisitos para poder ser postulado...",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      this.servicioInterno.persona_2_id = await cond["id"];
      this.servicioInterno.persona_2_type = await "App\\Conductor";
      this.persona_dos = await cond;
      this.cargando = false;
    },

    async buscarOperarios() {
      this.cargando = true;
      let dato = this.persona_dos.numero_documento;
      let oper = null;
      let fecha_ini = this.servicioInterno.fecha_ini1;
      let fecha_fin = this.servicioInterno.fecha_fin1;

      let res = await this.validarStatus(dato, "O", fecha_ini, fecha_fin, 1);
      if (res.data.length > 0) {
        oper = res["data"][0];

        //Valida la documentación del operario
        if (res.validacion.error) {
          this.cargando = false;
          await this.msgDocumentacion(res);
          if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) {
            this.persona_dos = {};
            this.servicioInterno.persona_2_id = null;
            this.servicioInterno.persona_2_type = null;
            return false;
          }
        }

        //Valida la línea de negocio del operario
        if (!res["has_lineas"]) {
          this.$swal({
            icon: "error",
            title: `El operario no se encuentra en la línea de negocio Carga Seca`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.persona_dos = {};
          this.servicioInterno.persona_2_id = null;
          this.servicioInterno.persona_2_type = null;
          this.cargando = false;
          return false;
        }

        //Valida las postulaciones del operario
        if (res.postulacion) {
          this.$swal({
            icon: "error",
            title: `El operario tiene postulaciones activas en otra solicitud.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.persona_dos = {};
          this.servicioInterno.persona_2_id = null;
          this.servicioInterno.persona_2_type = null;
          this.cargando = false;
          return false;
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El operario digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.cargando = false;
        this.persona_dos = {};
        this.servicioInterno.persona_2_id = null;
        this.servicioInterno.persona_2_type = null;
        return false;
      }

      this.$swal({
        icon: "success",
        title:
          "El operario cumple con los requisitos para poder ser postulado...",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      this.servicioInterno.persona_2_id = await oper["id"];
      this.servicioInterno.persona_2_type = await "App\\Operario";
      this.persona_dos = await oper;

      this.cargando = false;
    },

    /* selectAllPersonas(indexDet, indexDias, persona) {
      if (indexDias != null) {
        this.form1[indexDet][indexDias].persona = persona
          ? persona.numero_documento
          : null;
        this.form1[indexDet][indexDias].persona_name = persona
          ? `${persona.nombres} ${persona.apellidos}`
          : null;
        this.form1[indexDet][indexDias].persona_id = persona
          ? persona.id
          : null;
      } else {
        // Se asignan los valores al formulario
        for (let i = 0; i < this.diasPost; i++) {
          if (
            this.hoy <=
              moment(this.form1[indexDet][i].fecha).format("YYYY-MM-DD") &&
            !this.form1[indexDet][i].id
          ) {
            this.listasForms.persona[indexDet] = persona
              ? persona.numero_documento
              : null;
            this.listasForms.persona_name[indexDet] = persona
              ? `${persona.nombres} ${persona.apellidos}`
              : null;
            this.form1[indexDet][i].persona = persona
              ? persona.numero_documento
              : null;
            this.form1[indexDet][i].persona_name = persona
              ? `${persona.nombres} ${persona.apellidos}`
              : null;
            this.form1[indexDet][i].persona_id = persona ? persona.id : null;
          }
        }
      }
    }, */

    async validarStatus(
      dato,
      tipo,
      fecha_ini = null,
      fecha_fin = null,
      tipo_operario = null,
      fecha = null
    ) {
      let me = this;
      let url = "";
      let entidad = null;
      let id_det_solicitud_interna = [];
      me.servicioInterno.detalle_servicios.forEach((det) => {
        if (det.id) {
          id_det_solicitud_interna.push(det.id);
        }
      });
      if (tipo == "C") {
        entidad = "Conductor";
        url = "/api/cs/postulacionesInternas/listaConductores";
      } else if (tipo == "O") {
        entidad = "Operario";
        url = "/api/cs/postulacionesInternas/listaOperarios";
      }

      let params = {
        cs_det_solicitud_interna: [id_det_solicitud_interna],
        entidad,
        dato,
        fecha,
        fecha_ini: fecha_ini,
        fecha_fin: fecha_fin,
        tipo_operario: tipo_operario,
      };

      let res = null;
      await axios
        .get(url, { params })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let title = "";
        let erroHtml = "<div>";
        if (res.validacion.error.msg) {
          title = res.validacion.error.msg;
        }
        if (res.validacion.error.documentos) {
          title = "Aviso en documentación";
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title,
          showConfirmButton: false,
          showCloseButton: true,
          footer: `
            <small style='text-align:center'>
              <b>Desde el 1 de octubre se aplicarán las restricciones</b>,
              por favor tenga al día la documentación de sus vehículos,
              para mas información comuniquese con la mesa de ayuda al siguiente número
              <b>3176431376 Opción 3</b>
            </small>
          `,
        });
      }
    },

    verificacionPresuspuesto() {
      let rol = this.$store.state.user.roles.find((role) => role.id === 28);
      if (
        (this.$refs.CsServicioPresupuesto &&
          this.$refs.CsServicioPresupuesto.totalPresupuesto != 100) ||
        !rol
      ) {
        this.$swal({
          title: "El porcentaje es menor al 100% de la asignación contable?",
          text:
            "En estos momentos cuenta con el " +
            this.$refs.CsServicioPresupuesto.totalPresupuesto +
            " % ...",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Guardar",
        }).then((result) => {
          if (result.value) {
            this.saveTotal();
          }
        });
      } else {
        this.saveTotal();
      }
    },

    async saveTotal() {
      this.cargando = true;

      try {
        await this.save();
        await this.$refs.CsServicioDetalle.saveDetalleTotal();
        if (this.conductor_2) {
          await this.$refs.CsServicioDetalleSegundoConductor.saveDetalleTotal();
        }
        let rol = this.$store.state.user.roles.find((role) => role.id === 28);
        if (
          this.$store.getters.can("cs.serviciosInterno.presupuesto") &&
          !rol
        ) {
          await this.$refs.CsServicioPresupuesto.saveContableTotal();
        }
        await this.$swal({
          icon: "success",
          title: "Se guardó exitosamente...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } catch (error) {
        await this.$swal({
          icon: "error",
          title:
            "Ha ocurrido un error, por favor intente la acción nuevamente.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      this.cargando = false;
    },

    validarConductor(diffHoras) {
      if (diffHoras > 17) {
        this.conductor_2 = true;
        this.$swal({
          title: "Es necesesario agregar un segundo conductor",
          text: "Por temas de horario!",
          icon: "warning",
          confirmButtonText: "Ok!",
        });
      } else if (diffHoras > 12 && diffHoras < 17) {
        this.conductor_2 = false;
        this.servicioInterno.fecha_fin = "";
        this.servicioInterno.fecha_fin1 = "";
        this.servicioInterno.hora_fin1 = "";
        this.$swal({
          title: "Para agregar horas extra.",
          text: "Agregar en la parte inferior Reporte Trabajo (Botón Rojo)!",
          icon: "warning",
          confirmButtonText: "Ok!",
        });
      } else {
        this.conductor_2 = false;
      }
    },

    validaFecha() {
      if (
        this.servicioInterno.fecha_ini1 &&
        this.servicioInterno.hora_ini1 &&
        this.servicioInterno.hora_fin1 &&
        this.servicioInterno.fecha_fin1
      ) {
        const fecha_menor = new Date(
          `${this.servicioInterno.fecha_ini1} ${this.servicioInterno.hora_ini1}`
        );
        const fecha_mayor = new Date(
          `${this.servicioInterno.fecha_fin1} ${this.servicioInterno.hora_fin1}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.servicioInterno.fecha_fin = "";
          this.servicioInterno.fecha_fin1 = "";
          this.servicioInterno.hora_fin1 = "";
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio no puede ser mayor a la Fecha Fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }

        // Se valida que la diferencias de fechas no sea mayor a 24 horas
        const diffFechas = fecha_mayor.getTime() - fecha_menor.getTime();
        const diffHoras = diffFechas / (1000 * 60 * 60);

        if (
          diffHoras > this.postulacion.det_solicitud_interna.horasTurno ||
          diffHoras < this.postulacion.det_solicitud_interna.horasTurno
        ) {
          this.servicioInterno.fecha_fin = "";
          this.servicioInterno.fecha_fin1 = "";
          this.servicioInterno.hora_fin1 = "";
          this.$swal({
            icon: "error",
            title:
              `El turno para este servicio debe ser de: ` +
              this.postulacion.det_solicitud_interna.nTurno,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          if (this.servicioInterno.tipo_servicio == 1) {
            this.validarConductor(diffHoras);
          }
        }
      }
    },

    back() {
      this.$swal({
        title: "Esta seguro de volver?",
        text:
          "Recuerde guardar antes de salir, ya que podria perder el proceso actual..",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Volver!",
      }).then((result) => {
        if (result.value) {
          return this.$router.replace("/Cs/ServiciosInternos");
        }
      });
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getTipoServicio();
    await this.getTipoPresupuesto();
    await this.getEstados();
  },
};
</script>
