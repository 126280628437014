var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card card-info card-outline"},[_c('div',{staticClass:"card-header pt-2 pb-2"},[_c('div',{staticClass:"row justify-content-between"},[_c('h3',{staticClass:"card-title text-black text-bold"},[_c('i',{staticClass:"fa fa-file-signature text-navy"}),_vm._v(" Asignación Cargos Contables "),(_vm.$parent.servicioInterno.clase_solicitud == 1)?_c('small',[_vm._v("(Fijos)")]):_vm._e(),(_vm.$parent.servicioInterno.clase_solicitud == 2)?_c('small',[_vm._v("(LLamados)")]):_vm._e()]),(
            _vm.listasForms.asignaciones_contables.length > 0 &&
              _vm.$parent.servicioInterno.clase_solicitud == 1
          )?_c('div',[_c('v-select',{staticClass:"form-control form-control-sm p-0",staticStyle:{"min-width":"30em"},attrs:{"placeholder":"Asignación Contable","label":"nombre","options":_vm.listasForms.asignaciones_contables,"disabled":_vm.estado_contable == 1 || _vm.totalPresupuesto == 100},on:{"input":_vm.cambiarAsignaciones},model:{value:(_vm.asignacionContable),callback:function ($$v) {_vm.asignacionContable=$$v},expression:"asignacionContable"}})],1):_vm._e(),(_vm.$parent.servicioInterno.clase_solicitud == 2)?_c('div',{staticClass:"form-group col-md-8"},[_c('label',[_vm._v("Gerente Area")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gerente),expression:"gerente"}],staticClass:"form-control form-control-sm text-center",attrs:{"type":"text","disabled":""},domProps:{"value":(_vm.gerente)},on:{"input":function($event){if($event.target.composing)return;_vm.gerente=$event.target.value}}})])])]):_vm._e(),(_vm.$parent.servicioInterno.clase_solicitud == 2)?_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn bg-frontera text-white",attrs:{"type":"button","disabled":_vm.estado_contable == 1 || _vm.totalPresupuesto == 100},on:{"click":function($event){return _vm.addContable()}}},[_c('i',{staticClass:"fas fa-plus"})])]):_vm._e(),(
            _vm.asignacionContable && _vm.$parent.servicioInterno.clase_solicitud == 1
          )?_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn bg-danger text-white",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyContable('completo')}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]),(_vm.csContables.length > 0)?_c('div',{staticClass:"col-md-12 mb-0 text-center",class:_vm.totalPresupuesto == 100
            ? 'text-success'
            : _vm.totalPresupuesto < 100
            ? 'text-warning'
            : 'text-danger'},[_c('h4',[_vm._v("Porcentaje Total: "+_vm._s(_vm.totalPresupuesto)+"%")])]):_vm._e()]),(_vm.csContables.length > 0 || _vm.estado_contable == 1)?_c('div',{staticClass:"card-body table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm p-2"},[_c('thead',{staticClass:"bg-dark"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Area")]),_c('th',{staticClass:"text-center"},[_vm._v("Tipo Presupuesto")]),_c('th',{staticClass:"text-center"},[_vm._v("CECO/AFE/G&A")]),_c('th',{staticClass:"text-center text-nowrap"},[_vm._v("Responsable")]),_c('th',{staticClass:"text-center"},[_vm._v("Activo Producción")]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"8em"}},[_vm._v(" % de la operación para cargar este CECO/AFE ")]),(_vm.$parent.servicioInterno.clase_solicitud == 2)?_c('th',{staticStyle:{"width":"10em"}},[_vm._v(" Acciones ")]):_vm._e()])]),_c('tbody',{attrs:{"id":"tbody"}},[_vm._l((_vm.csContables),function(csContable,index){return _c('tr',{key:csContable.id},[_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('v-select',{staticClass:"form-control form-control-sm mr-3 p-0",class:_vm.$v.contable.area.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"Areas","label":"descripcion","options":_vm.listasForms.areas},on:{"input":function($event){return _vm.getSelectArea()}},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})],1):_c('div',[_vm._v(" "+_vm._s(csContable.nArea)+" ")])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('v-select',{staticClass:"form-control form-control-sm mr-3 p-0",class:[
                  _vm.$v.contable.tipo_presupuesto.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Tipo Presupuesto","label":"descripcion","options":_vm.$parent.listasForms.tipo_presupuesto},on:{"input":function($event){[_vm.resetCecoAfe(), _vm.getCecosAfes()]}},model:{value:(_vm.contable.tipoPresupuesto),callback:function ($$v) {_vm.$set(_vm.contable, "tipoPresupuesto", $$v)},expression:"contable.tipoPresupuesto"}}):_c('span',{staticClass:"badge",class:csContable.tipo_presupuesto == 1
                    ? 'bg-info'
                    : csContable.tipo_presupuesto == 2
                    ? 'bg-navy'
                    : 'bg-lime'},[_vm._v(" "+_vm._s(csContable.tipo_presupuesto ? csContable.nTipoPresupuesto : null)+" ")])],1),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('v-select',{staticClass:"form-control form-control-sm mr-3 p-0",class:_vm.$v.contable.ceco_afe_id.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"Ceco Afe","label":"codigo_contable","options":_vm.listasForms.ceco_afes,"disabled":_vm.contable.tipo_presupuesto == null},on:{"input":function($event){return _vm.getSelectCeco()}},model:{value:(_vm.ceco_afe),callback:function ($$v) {_vm.ceco_afe=$$v},expression:"ceco_afe"}}):_c('v-select',{staticClass:"form-control form-control-sm mr-3 p-0",attrs:{"placeholder":"Ceco Afe","label":"codigo_contable","options":_vm.listasForms.ceco_afes,"disabled":""},model:{value:(csContable.ceco_afe),callback:function ($$v) {_vm.$set(csContable, "ceco_afe", $$v)},expression:"csContable.ceco_afe"}})],1),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('v-select',{staticClass:"form-control form-control-sm p-0 mr-3 p-0",class:[
                    _vm.$v.contable.responsable_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                  ],attrs:{"placeholder":"Nombre Responsable","label":"funcionario","options":_vm.listasForms.responsables},on:{"input":_vm.getSelectResposable},model:{value:(_vm.responsable),callback:function ($$v) {_vm.responsable=$$v},expression:"responsable"}})],1):_c('div',[(csContable.responsable.nombres)?_c('div',[_vm._v(" "+_vm._s(csContable.responsable.nombres ? csContable.responsable.nombres + " " + csContable.responsable.apellidos : "")+" ")]):_c('div',[_vm._v(_vm._s(csContable.responsable.funcionario))])])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('v-select',{staticClass:"form-control form-control-sm mr-3 p-0",class:[
                    _vm.$v.contable.activo_produccion.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                  ],attrs:{"placeholder":"Tipo Presupuesto","label":"descripcion","options":_vm.listasForms.activosProduccion},on:{"input":_vm.getSelectActivosProduccion},model:{value:(_vm.activo_produccion),callback:function ($$v) {_vm.activo_produccion=$$v},expression:"activo_produccion"}})],1):_c('div',[_vm._v(" "+_vm._s(csContable.nActivoProduccion)+" ")])]),_c('td',{staticClass:"text-center"},[(_vm.id_actualizar_contable == index && _vm.estado_contable == 2)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contable.porcentaje),expression:"contable.porcentaje"}],staticClass:"form-control form-control-sm",class:_vm.$v.contable.porcentaje.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"number"},domProps:{"value":(_vm.contable.porcentaje)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.contable, "porcentaje", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(csContable.porcentaje))])]),(_vm.$parent.servicioInterno.clase_solicitud == 2)?_c('td',[(_vm.estado_contable != 2)?_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":_vm.estado_contable != -1},on:{"click":function($event){return _vm.editContable(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":_vm.estado_contable != -1},on:{"click":function($event){return _vm.destroyContable(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(_vm.estado_contable == 2 && _vm.id_actualizar_contable == index)?_c('div',{staticClass:"btn-group float-right"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.contable.$invalid),expression:"!$v.contable.$invalid"}],staticClass:"btn bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveUpdateContable(index)}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelContable()}}},[_c('i',{staticClass:"fas fa-ban"})])]):_vm._e(),(
                  _vm.estado_contable == 2 &&
                    _vm.id_actualizar_contable !== index &&
                    _vm.$parent.servicioInterno.clase_solicitud == 2
                )?_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":""},on:{"click":function($event){return _vm.editContable(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":""},on:{"click":function($event){return _vm.destroyContable(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]):_vm._e()])}),(_vm.estado_contable == 1)?_c('tr',[_c('td',[_c('v-select',{staticClass:"form-control form-control-sm mr-3 p-0",class:_vm.$v.contable.area.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"Areas","label":"descripcion","options":_vm.listasForms.areas},on:{"input":function($event){return _vm.getSelectArea()}},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})],1),_c('td',[_c('v-select',{staticClass:"form-control form-control-sm mr-3 p-0",class:[
                  _vm.$v.contable.tipo_presupuesto.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Tipo Presupuesto","label":"descripcion","options":_vm.$parent.listasForms.tipo_presupuesto},on:{"input":function($event){[_vm.resetCecoAfe(), _vm.getCecosAfes()]}},model:{value:(_vm.contable.tipoPresupuesto),callback:function ($$v) {_vm.$set(_vm.contable, "tipoPresupuesto", $$v)},expression:"contable.tipoPresupuesto"}})],1),_c('td',[_c('v-select',{staticClass:"form-control form-control-sm mr-3 p-0",class:_vm.$v.contable.ceco_afe_id.$invalid ? 'is-invalid' : 'is-valid',attrs:{"placeholder":"Ceco Afe","label":"codigo_contable","options":_vm.listasForms.ceco_afes,"disabled":_vm.contable.tipo_presupuesto == null},on:{"input":function($event){return _vm.getSelectCeco()}},model:{value:(_vm.ceco_afe),callback:function ($$v) {_vm.ceco_afe=$$v},expression:"ceco_afe"}})],1),_c('td',[_c('v-select',{staticClass:"form-control form-control-sm p-0 mr-3 p-0",class:[
                  _vm.$v.contable.responsable_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Nombre Responsable","label":"funcionario","options":_vm.listasForms.responsables},on:{"input":_vm.getSelectResposable},model:{value:(_vm.responsable),callback:function ($$v) {_vm.responsable=$$v},expression:"responsable"}})],1),_c('td',[_c('v-select',{staticClass:"form-control form-control-sm p-0 mr-3 p-0",class:[
                  _vm.$v.contable.activo_produccion.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Tipo Presupuesto","label":"descripcion","options":_vm.listasForms.activosProduccion},on:{"input":_vm.getSelectActivosProduccion},model:{value:(_vm.activo_produccion),callback:function ($$v) {_vm.activo_produccion=$$v},expression:"activo_produccion"}})],1),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contable.porcentaje),expression:"contable.porcentaje"}],staticClass:"form-control form-control-sm p-0 mr-3 p-0",class:_vm.$v.contable.porcentaje.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"number","id":"porcentaje"},domProps:{"value":(_vm.contable.porcentaje)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.contable, "porcentaje", $event.target.value)},function($event){return _vm.limitarTresDigitos()}]}})]),_c('td',[_c('div',{staticClass:"btn-group float-right"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.contable.$invalid),expression:"!$v.contable.$invalid"}],staticClass:"btn bg-success",on:{"click":function($event){return _vm.saveContable()}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelContable()}}},[_c('i',{staticClass:"fas fa-trash"})])])])]):_vm._e()],2)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }